import { Box, Typography } from '@mui/material'
import React from 'react'

const Heading = ({...props}) => {
  return (
    <Box sx={{
        display:"flex",
        flexDirection:"row",
        gap:"4px"
        }}>
        <Box
        sx={{
            border:"3px solid #002060",
            borderRadius:"4px"
        }}
        >
        </Box>
        <Typography   sx={{fontWeight:"600",color:"#002060",fontSize:{xs:"16px",md:"18px"}}}>{props.value}</Typography> 
      
    </Box>
  )
}

export default Heading;
