import { Box, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appc, successLogo, telanganaLogo } from "../assets";
import SuccessComponent from "../components/SuccessComponent";
import {
  StyledSuccessBackgroundComponent,
  StyledSuccessComponent,
  StyledSuccessComponentDate,
} from "../styles/styledComponent";
import Image from "../components/Image";

const Success = () => {
  const location = useLocation();
  const studentData = location.state?.studentData;
  const navigate = useNavigate();

  useEffect(() => {
    if (!studentData) {
      navigate("/");
    }
  }, [studentData, navigate]);
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours24 = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, "0");

  const period = hours24 >= 12 ? "PM" : "AM";
  const hours12 = hours24 % 12 || 12;

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours12}:${minutes} ${period}`;

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "end",
        paddingTop: "2%",
        paddingLeft: "7%",
        paddingRight: "5%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          height: { xs: "70px", md: "100px" },
        }}
      >
        <Image src={telanganaLogo} />

        <Typography
          color="primary"
          fontWeight="600"
          sx={{
            color: "#1E4B8F",
            fontSize: { xs: "18px", md: "28px" },
          }}
        >
          APPC Online Training to TBOCWWB Trainee Feedback Form
        </Typography>
        <Image src={appc} />
      </Box>
      <Stack alignItems={"center"} width={"100%"}>
        <Lottie
          animationData={successLogo}
          style={{ width: "350px", height: "350px" }}
        />
      </Stack>
      <Stack alignItems={"center"} width={"100%"}>
        <Typography sx={{ color: "#121212", fontWeight: "600" }}>
          Thank you for your valuable feedback on APPC Online training
        </Typography>
      </Stack>
      <StyledSuccessBackgroundComponent>
        <StyledSuccessComponentDate>
          <SuccessComponent label="Student Name: " value={studentData?.name} />
          <SuccessComponent label="APPC Id:" value={studentData?.formId} />
        </StyledSuccessComponentDate>
        <StyledSuccessComponent>
          <SuccessComponent label="Date:" value={formattedDate} />
          <SuccessComponent label="Time:" value={formattedTime} />
        </StyledSuccessComponent>
      </StyledSuccessBackgroundComponent>
    </Box>
  );
};

export default Success;
