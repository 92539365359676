import { http } from "../http";

export const getByMobileNumber = ({ queryKey }: any) => {
  return http.get(`/registration-form/trainee/${queryKey[1]}`);
};

export const submitFeedback = (body: any) => {
  return http.post("/feedback", body);
};

export const requestOtp = (body: any) => {
  return http.post("/auth/request-otp", body);
};

export const verifyOtp = (body: any) => {
  return http.post("/auth/verify-otp", body);
};
