import { Box, Typography } from "@mui/material";

const PreviewComponent = ({ index, label, value, options }: any) => {
  return (
    <Box sx={{ gap: "0px", width: { xs: "90%", sm: "60%", md: "40%" } }}>
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Typography
          sx={{
            my: "5px",
            fontSize: "16px",
            marginTop: "15px",
            fontWeight: "500",
            opacity: "60%",
            whiteSpace: "nowrap",
          }}
        >
          {index + 1})
        </Typography>
        <Typography
          sx={{
            my: "5px",
            fontSize: "16px",
            marginTop: "15px",
            fontWeight: "500",
            opacity: "60%",
            // whiteSpace: "nowrap",
          }}
        >
          {label}
        </Typography>
      </Box>

      {options.map((o: any, index: number) => {
        const optionLabel = String.fromCharCode(65 + index);
        return o === value ? (
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Typography
              variant="h6"
              sx={{ color: "red", opacity: "100%", fontWeight: "600" }}
            >
              {optionLabel})
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "red", opacity: "100%", fontWeight: "600" }}
            >
              {o}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Typography>{optionLabel})</Typography>
            <Typography>{o}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default PreviewComponent;
