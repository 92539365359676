import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import Form from "../pages/form";
import Login from "../pages/Login";
import Success from "../pages/success";

const RoutesContainer = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    // element: <Layout />,
    children: [
      {
        path: "/form",
        element: <Form />,
      },
      {
        path: "/success",
        element: <Success />,
      },
    ],
  },
]);

export default RoutesContainer;
