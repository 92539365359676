import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { appc, telanganaLogo } from "../assets";
import SelectComponent from "../components/FormFields/FormSelect";
import Image from "../components/Image";
import Toast from "../components/Toast/Toast";
import {
  StyledFeedBackComponent,
  StyledFeedbackStackComponent,
} from "../styles/styledComponent";
import { validationSchema } from "../validations/FeedbackFormValidations";
import PreviewForm from "./preview";

export const courseExpectation = [
  "Strongly agree",
  "Agree",
  "Neutral",
  "Strongly disagree",
];
export const teachingEffectiveness = [
  "Extremely effective",
  "Slightly effective",
  "Ineffective",
  "Not applicable",
];
export const assessmentMethod = [
  "End-of-course exam",
  "Continuous assessment",
  "Practical assignments",
  "No formal assessment",
];
export const recommendation = [
  "Would not recommend",
  "Neutral",
  "Recommend",
  "Highly Recommend",
];
export const overallOpinion = [
  "Useful",
  "Not Useful",
  "Not Sure",
  "Very Useful",
];

export const opportunity = [
  "Not Applicable",
  "No Opportunities",
  "Few Opportunities",
  "Many Opportunities",
];

const Form = () => {
  const location = useLocation();
  const studentData = location.state?.studentData;
  const navigate = useNavigate();

  useEffect(() => {
    if (!studentData) {
      navigate("/");
    }
  }, [studentData, navigate]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    return () => {
      window.history.scrollRestoration = "auto";
    };
  }, []);

  const defaultValues = {
    courseExpectation: "",
    teachingEffectiveness: "",
    assessmentMethod: "",
    opportunity: "",
    recommendation: "",
    overallOpinion: "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const [buttonValue, setButtonValue] = useState("next");
  const [formData, setFormData] = useState<any>({});
  const [error, setError] = useState(Object.keys(errors)?.length);

  const onSubmit = async (data: any) => {
    setFormData(data);
    setButtonValue("preview");
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const handleReset = () => {
    setButtonValue("next");
  };
  if (error > 0) {
    return (
      <Toast
        variant="error"
        message={"Fill all the fields"}
        onClose={() => setError(0)}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      {buttonValue === "next" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledFeedBackComponent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "center",
                height: { xs: "70px", md: "100px" },
                overflowY: "auto",
                maxHeight: "100vh",
              }}
            >
              <Image src={telanganaLogo} />

              <Typography
                color="primary"
                fontWeight="600"
                sx={{
                  color: "#1E4B8F",
                  fontSize: { xs: "18px", md: "28px" },
                }}
              >
                APPC Online Training to TBOCWWB Trainee Feedback Form
              </Typography>
              <Image src={appc} />
            </Box>
            <Box sx={{ padding: "2%" }}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={2}
                sx={{ marginTop: "20px" }}
              >
                <Box flex={1}>
                  <Stack spacing={2}>
                    <Typography variant="h6">
                      <Box component="span" fontWeight="fontWeightBold">
                        Trainee Name:
                      </Box>
                      {` ${studentData?.name}`}
                    </Typography>

                    <Typography variant="h6">
                      <Box component="span" fontWeight="fontWeightBold">
                        District:
                      </Box>
                      {` ${studentData?.district?.name}`}
                    </Typography>
                    <Typography variant="h6">
                      <Box component="span" fontWeight="fontWeightBold">
                        Course Name:
                      </Box>
                      {` ${studentData?.course?.name}`}
                    </Typography>
                    <Typography variant="h6">
                      <Box component="span" fontWeight="fontWeightBold">
                        BOCW ID:
                      </Box>
                      {` ${studentData?.bocRegNo}`}
                    </Typography>
                    <Typography variant="h6">
                      <Box component="span" fontWeight="fontWeightBold">
                        APPC ID:
                      </Box>
                      {` ${studentData?.formId}`}
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    height: "200px",
                    alignSelf: { xs: "flex-start", md: "flex-end" },
                  }}
                >
                  <img
                    src={studentData?.imageUrl}
                    alt="Student Photo"
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              </Stack>
              <Stack>
                <StyledFeedbackStackComponent>
                  <SelectComponent
                    data={courseExpectation}
                    control={control}
                    name={"courseExpectation"}
                    label={"The course content met your expectations."}
                  />
                  <SelectComponent
                    data={teachingEffectiveness}
                    control={control}
                    name={"teachingEffectiveness"}
                    label={"Instructors teaching methods are effective. "}
                  />
                </StyledFeedbackStackComponent>
                <StyledFeedbackStackComponent>
                  <SelectComponent
                    data={assessmentMethod}
                    control={control}
                    name={"assessmentMethod"}
                    label={"Assessment methods used during the training "}
                  />
                  <SelectComponent
                    data={opportunity}
                    control={control}
                    name={"opportunity"}
                    label={
                      "Opportunities for career advancement post -training"
                    }
                  />{" "}
                </StyledFeedbackStackComponent>
                <StyledFeedbackStackComponent>
                  <SelectComponent
                    data={recommendation}
                    control={control}
                    name={"recommendation"}
                    label={
                      "Would you Recommend others take up similar training?"
                    }
                  />
                  <SelectComponent
                    data={overallOpinion}
                    control={control}
                    name={"overallOpinion"}
                    label={
                      "Overall opinion about the APPC Online Skill Training programme"
                    }
                  />
                </StyledFeedbackStackComponent>
              </Stack>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: { xs: "25%", sm: "15%", md: "15%" },
                  fontWeight: "500",
                  marginTop: { xs: "20px", sm: "30px", md: "40px" }, // Adjust these values as needed
                }}
              >
                Next
              </Button>
            </Box>
          </StyledFeedBackComponent>
        </form>
      )}
      {buttonValue === "preview" && (
        <PreviewForm
          setButtonValue={setButtonValue}
          formData={formData}
          studentData={studentData}
        />
      )}

      {Object.keys(errors)?.length > 0 && (
        <Toast
          variant="error"
          message={"Fill all the fields"}
          onClose={() => setError(0)}
        />
      )}
    </FormProvider>
  );
};

export default Form;
