import { Box, FormHelperText, FormLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormInput = ({ control, name, label, value }:any) => {
  return (
    <>
    {control && name ? (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }:any) => (
            <>
              <TextField
                {...field}
                type='number' 
                placeholder={label}
                disabled={false} 
                sx={{
                  width:"100%",
                  "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
                error={!!fieldState.error}
                />
                {fieldState?.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
              </>
          )}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: "10px", gap: "8px", width: { xs: "80%", sm: "60%", md: "40%" } }}>
          <FormLabel sx={{ fontWeight: "500", opacity: "0.5" }}>{label}</FormLabel>
        <TextField
          placeholder={label}
          disabled
          defaultValue={value}
          InputProps={{
            sx: {
              borderRadius: '10px',
              backgroundColor: "#F8F8F8",
              color: "#222222",
              opacity: "1",
              fontWeight: "600",
              fontSize: "16px",
              '&.Mui-disabled': {
                color: "#222222",
                opacity: 1,
                fontWeight: "600",
                fontSize: "16px",
              }
            },
          }}
        />
    </Box>
      )}
    </>
  );
}

export default FormInput;
