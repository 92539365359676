//Images
import appc from "./images/appc.png";
import banner from "./images/loginBanner.png";
import successLogo from "./images/successLogo.json";
import telanganaLogo from "./images/telanganaLogo.png";

//Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export { appc, banner, successLogo, telanganaLogo };

export const Icons = {
  ArrowBackIcon,
};
