import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { useEffect, useState } from 'react';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const Toast = ({ variant, message }: any) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, 5000); // Auto-close after 5 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            zIndex: 9999,
            width: {xs:"200px",sm:"230px",md:'400px',},
            padding: '12px',
            borderRadius: '8px',
            background: variant === 'error' ? '#FBE7E5' : '#E1F7EC',
            // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            animation: `${slideIn} 0.3s ease forwards, ${slideOut} 0.3s ease-in 4.7s forwards`,
            marginBottom: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {variant === 'error' ? (
              <ErrorOutlineIcon sx={{ color: '#FF4D4F', marginRight: '8px' }} />
            ) : (
              <CheckCircleIcon sx={{ color: '#4CAF50', marginRight: '8px' }} />
            )}
            <Typography variant="body1" sx={{ color: variant === 'error' ? '#FF4D4F' : '#4CAF50' }}>
              {message}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Toast;
