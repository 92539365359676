import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { submitFeedback } from "../api/service";
import { appc, Icons, telanganaLogo } from "../assets";
import Heading from "../components/Heading";
import Image from "../components/Image";
import Loader from "../components/Loader";
import PreviewComponent from "../components/PreviewComponent";
import Toast from "../components/Toast/Toast";
import {
  StyledFeedBackComponent,
  StyledFeedbackStackComponent,
} from "../styles/styledComponent";
import {
  assessmentMethod,
  courseExpectation,
  opportunity,
  overallOpinion,
  recommendation,
  teachingEffectiveness,
} from "./form";

const Preview = ({ setButtonValue, formData, studentData }: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(true);
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(submitFeedback, {
    onSuccess: () => {
      navigate("/success", { state: { studentData } });
      setButtonValue("success");
      setIsSubmitting(false);
    },
    onError: (error: any) => {
      setIsSubmitting(true);
      const message = error?.response?.data?.message || "An error occurred";
      setErrorMessage(message);
      setTimeout(() => setErrorMessage(""), 3000);
    },
  });

  const handleBackClick = () => setButtonValue("next");
  const handleFormSubmit = () => {
    setIsSubmitting(false);
    const preparedData = {
      ...formData,
      formId: studentData?.formId,
    };
    mutate(preparedData);
  };

  const sections = [
    {
      title: "Details",
      fields: [
        {
          label: "The course content met with your expectations",
          value: formData?.courseExpectation,
          options: courseExpectation,
        },
        {
          label: "Instructors teaching method effectiveness",
          value: formData?.teachingEffectiveness,
          options: teachingEffectiveness,
        },
        {
          label: "Assessment methods used during the training",
          value: formData?.assessmentMethod,
          options: assessmentMethod,
        },
        {
          label: "Opportunities for career advancement post -training ",
          value: formData?.opportunity,
          options: opportunity,
        },
        {
          label: "Recommendations for others considering similar training",
          value: formData.recommendation,
          options: recommendation,
        },
        {
          label:
            "Overall opinion about the APPC Online Skill Training programme",
          value: formData.overallOpinion,
          options: overallOpinion,
        },
      ],
    },
  ];

  return (
    <>
      <StyledFeedBackComponent>
        <Box
          sx={{
            paddingLeft: { xs: "10px", md: "1%", lg: "0" },
          }}
        >
          <Button
            variant="outlined"
            sx={{
              height: "40px",
              width: { xs: "40%", sm: "30%", md: "10%" },
              padding: { xs: "8px 16px", md: "10px 20px" },
              fontSize: { xs: "14px", md: "16px" },
              marginBottom: { xs: "10px", md: "0" },
            }}
            startIcon={<Icons.ArrowBackIcon />}
            onClick={handleBackClick}
          >
            Back
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            height: { xs: "70px", md: "100px" },
          }}
        >
          <Image src={telanganaLogo} />

          <Typography
            color="primary"
            fontWeight="600"
            sx={{
              fontSize: { xs: "18px", md: "28px" },
            }}
          >
            APPC Online Training to TBOCWWB Trainee Feedback Form
          </Typography>
          <Image src={appc} />
        </Box>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          padding={"2%"}
          sx={{ marginTop: "20px" }}
        >
          <Box flex={1}>
            <Stack spacing={2}>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightBold">
                  Trainee Name:
                </Box>
                {` ${studentData?.name}`}
              </Typography>

              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightBold">
                  District:
                </Box>
                {` ${studentData?.district?.name}`}
              </Typography>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightBold">
                  Course Name:
                </Box>
                {` ${studentData?.course?.name}`}
              </Typography>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightBold">
                  BOCW ID:
                </Box>
                {` ${studentData?.bocRegNo}`}
              </Typography>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightBold">
                  APPC ID:
                </Box>
                {` ${studentData?.formId}`}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              height: "200px",
              alignSelf: { xs: "flex-start", md: "flex-end" },
            }}
          >
            <img
              src={studentData?.imageUrl}
              alt="Student Photo"
              width={"100%"}
              height={"100%"}
            />
          </Box>
        </Stack>

        <StyledFeedBackComponent sx={{ padding: "2%" }}>
          {sections.map((section) => (
            <Stack key={section.title}>
              <Heading value={section.title} />
              {section.fields.map(
                (field, index, options) =>
                  index % 2 === 0 && (
                    <StyledFeedbackStackComponent key={field.label}>
                      <PreviewComponent
                        index={index}
                        label={section.fields[index].label}
                        value={section.fields[index].value}
                        options={section.fields[index].options}
                      />
                      {section.fields[index + 1] && (
                        <PreviewComponent
                          index={index + 1}
                          label={section.fields[index + 1].label}
                          value={section.fields[index + 1].value}
                          options={section.fields[index + 1].options}
                        />
                      )}
                    </StyledFeedbackStackComponent>
                  )
              )}
            </Stack>
          ))}
          <Box sx={{ mt: "23px" }}>
            {isLoading ? (
              <Box
                sx={{
                  border: "2px solid #1E4B8F",
                  width: { xs: "30%", sm: "25%", md: "22%" },
                  height: "35px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  alignContent: "center",
                }}
              >
                <Loader color={"#1E4B8F"} />
              </Box>
            ) : (
              <Button
                variant="contained"
                onClick={handleFormSubmit}
                type="submit"
                sx={{
                  width: { xs: "25%", sm: "15%", md: "15%" },
                  marginTop: { xs: "10px", sm: "20px", md: "30px" }, // Added marginTop
                }}
              >
                Submit
              </Button>
            )}
          </Box>
          {/* {isSubmitting ? (
            
          ) : (
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              sx={{
                width: { xs: "25%", sm: "15%", md: "15%" },
                marginTop: { xs: "20px", sm: "30px", md: "40px" }, // Added marginTop
              }}
            >
              {isLoading && <Loader />}
            </Button>
          )} */}
          {errorMessage && (
            <Toast
              variant="error"
              message={errorMessage}
              onClose={() => setErrorMessage("")}
            />
          )}
        </StyledFeedBackComponent>
      </StyledFeedBackComponent>
    </>
  );
};

export default Preview;
