import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  mobileNo: yup
    .string()
    .required("Mobile Number is required")
    .length(10, "Mobile number should be exactly 10 digits")
    .matches(/^[0-9]+$/, "Must be a Number"),
});

export const otpValidationSchema = yup.object().shape({
  Otp: yup
    .string()
    .required("OTP is required")
    .length(4, "OTP should be exactly 4 digits")
    .matches(/^[0-9]+$/, "Must be a Number"),
});
