import { createTheme, ThemeOptions } from "@mui/material";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#1E4B8F",
    },
    secondary: {
      main: "#002060",
    },
    info: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: "bold",
          fontFamily: "Poppins",
          fontSize: "1rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#020202",
          fontFamily: "Poppins",
        },
        h1: {
          fontFamily: "Poppins",
          fontSize: "40px",
          fontWeight: "400",
          opacity: "1",
          color: "#020202",
          letterSpacing: "0px",
        },
        h2: {
          fontFamily: "Poppins",
          fontWeight: "400",
          opacity: "1",
          letterSpacing: "0px",
          fontSize: "28px",
          fontStyle: "normal",
        },
        h3: {
          fontFamily: "Poppins",
          fontSize: "22px",
          fontWeight: "400",
          opacity: "1",
          color: "#020202",
          letterSpacing: "0px",
        },
        h4: {
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: "400",
          opacity: "1",
          color: "#020202",
          letterSpacing: "0px",
        },
        h5: {
          fontFamily: "Poppins",
          fontWeight: "400",
          opacity: "1",
          color: "#020202",
          letterSpacing: "0px",
          fontSize: "18px",
        },
        h6: {
          fontFamily: "Poppins",
          fontWeight: "400",
          opacity: "1",
          color: "#020202",
          letterSpacing: "0px",
          fontSize: "16px",
        },
        subtitle1: {
          color: "#020202",
          letterSpacing: "0px",
          opacity: "85%",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: "300",
        },
        subtitle2: {
          fontWeight: "400",
          color: "#020202",
          letterSpacing: "0px",
          opacity: "85%",
          fontFamily: "Poppins",
          fontSize: "16px",
        },
        body1: {
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#020202",
          letterSpacing: "0px",
          opacity: "85%",
          fontSize: "16px",
        },
        body2: {
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#020202",
          letterSpacing: "0px",
          opacity: "85%",
          fontSize: "13px",
        },
        caption: {
          fontFamily: "Poppins",
          fontSize: "12px",
          opacity: "75%",
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);
export default theme;
