import * as yup from "yup";

export const validationSchema = yup.object().shape({
  courseExpectation: yup.string().required("This field is required"),
  teachingEffectiveness: yup.string().required("This field is required"),
  assessmentMethod: yup.string().required("This field is required"),
  opportunity: yup.string().required("This field is required"),
  recommendation: yup.string().required("This field is required"),
  overallOpinion: yup.string().required("This field is required"),
});
