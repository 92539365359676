import axios from "axios";

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message === "Network Error") {
      alert("Network Error");
    }

    if (
      error.response.data.statusCode === 401 &&
      error.response.config.method === "get"
    ) {
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);
