import { Box, Stack, styled } from "@mui/material";

export const StyledLoginBackground = styled(Box)`
  display: flex;
  overflow: hidden;

  width: 100%;
  height: 100vh;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: column;
  }
  @media (min-width: 960px) {
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
  }
  @media (min-width: 1920px) {
    flex-direction: row;
  }
`;

export const StyledImageBackground = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 50%;

  @media (min-width: 960px) {
    width: 50%;
    height: 100%;
  }
`;

export const StyledFormComponent = styled(Box)`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 1rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
    height: 100%;
    padding: 5px 2rem;
  }
`;

export const StyledNavbarComponent = styled(Box)`
  display: flex;
  width: 100%;
  height: 10vh;
  z-index: 1000;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  opacity: 100%;
  position: fixed;
  padding-left: 5%;
`;

export const StyledFeedBackComponent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
  padding: 5%;
  @media (min-width: 600px) {
    gap: 15px;
  }
  @media (min-width: 1280px) {
    gap: 30px;
  }
`;
export const StyledFeedbackStackComponent = styled(Stack)`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  
  @media (min-width: 700px) {
    flex-direction: column;
    gap: 0px; 
  }

  @media (min-width: 960px) {
    flex-direction: row; 
    gap: 15px; 
    align-items:flex-end;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    gap: 15px; 
    align-items:flex-start;
`;

export const StyledSuccessComponentDate = styled(Stack)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column", // Default for xs
  width: "100%", // Default for xs
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    width: "55%",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    width: "37%",
  },
}));
export const StyledSuccessComponent = styled(Stack)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column", // Default for xs
  width: "100%", // Default for xs
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    width: "55%",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    width: "55%",
  },
}));

export const StyledSuccessBackgroundComponent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));
