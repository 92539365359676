import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getByMobileNumber, requestOtp, verifyOtp } from "../api/service/index";
import { banner } from "../assets";
import FormInput from "../components/FormFields/FormInput";
import Loader from "../components/Loader";
import Toast from "../components/Toast/Toast";
import {
  StyledFormComponent,
  StyledImageBackground,
  StyledLoginBackground,
} from "../styles/styledComponent";
import {
  loginValidationSchema,
  otpValidationSchema,
} from "../validations/LoginValidation";

const Login = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isOptScreen, setIsOtpScreen] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [studentInfo, setStudentInfo] = useState<any>();
  const [isClicked, setIsClicked] = useState(false);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get("source");

  const defaultValues = {
    mobileNo: "",
  };
  const isMobile = useMediaQuery("(max-width:960px)");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(loginValidationSchema),
  });

  const {
    handleSubmit: otpSubmit,
    control: otpControl,
    getValues,
  } = useForm({
    resolver: yupResolver(otpValidationSchema),
  });

  const { mutate: sendOtp, isLoading } = useMutation(requestOtp, {
    onSuccess: (res: any) => {
      setIsOtpScreen(true);
      setToken(res?.data?.access_token);
      localStorage.setItem("access_token", res?.data?.access_token);
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "An error occurred";
      setErrorMessage(message);
      setTimeout(() => setErrorMessage(""), 3000);
    },
  });

  const { mutate, isLoading: verifyLoading } = useMutation(verifyOtp, {
    onSuccess: (res: any) => {
      navigate("/form", { state: { studentData: res?.data?.form } });
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "An error occurred";
      setErrorMessage(message);
      setTimeout(() => setErrorMessage(""), 3000);
    },
  });

  const { data, isLoading: dataLoading } = useQuery(
    ["mobile", mobile],
    getByMobileNumber,
    {
      enabled: param === "edmingle" && isClicked,
      onSuccess: (data: any) => {
        navigate("/form", { state: { studentData: data?.data } });
      },
      onError: (error: any) => {
        const message = error?.response?.data?.message || "An error occurred";
        setErrorMessage(message);
        setTimeout(() => setErrorMessage(""), 3000);
      },
    }
  );

  const onVerifyOtp = (data: any) => {
    mutate({
      mobile: mobile,
      otp: getValues("Otp"),
      token: token,
    });
  };

  const handleRequestOtp = (data: any) => {
    setMobile(data.mobileNo);
    if (param === "edmingle") {
      setIsClicked(true);
      queryClient.invalidateQueries("mobile");
    } else {
      sendOtp({ mobile: data.mobileNo });
    }
  };

  return (
    <StyledLoginBackground>
      <StyledImageBackground>
        <img
          src={banner}
          alt="banner"
          width={"100%"}
          style={{
            width: "100%",
            height: "100%",
            objectFit: isMobile ? "cover" : "contain",
          }}
        />
      </StyledImageBackground>
      {!isOptScreen && (
        <StyledFormComponent>
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleRequestOtp)}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box>
                <Stack direction={"row"} gap={0.5}>
                  <Typography variant="h6" sx={{ fontWeight: "500" }}>
                    Enter Mobile Number
                  </Typography>
                  <Typography color="red">*</Typography>
                </Stack>
                <FormInput
                  control={control}
                  value={mobile}
                  name={"mobileNo"}
                  label={"Enter the 10 digit mobile number"}
                />
              </Box>
            </Box>
            <Box sx={{ mt: "23px" }}>
              {isLoading ? (
                <Box
                  sx={{
                    border: "2px solid #1E4B8F",
                    width: { xs: "30%", sm: "25%", md: "22%" },
                    height: "35px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    alignContent: "center",
                  }}
                >
                  <Loader color={"#1E4B8F"} />
                </Box>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: { xs: "30%", sm: "25%", md: "22%" },
                    height: "35px",
                    borderRadius: "8px",
                    fontSize: "14px",
                  }}
                >
                  {param === "edmingle" ? "submit" : "Get OTP"}
                </Button>
              )}
            </Box>
            {errorMessage && (
              <Toast
                variant="error"
                message={errorMessage}
                onClose={() => setErrorMessage("")}
              />
            )}
          </form>
        </StyledFormComponent>
      )}
      {isOptScreen && (
        <StyledFormComponent>
          <form style={{ width: "100%" }} onSubmit={otpSubmit(onVerifyOtp)}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                // gap: "10px",
              }}
            >
              <Box>
                <Stack direction="column" alignItems="center">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Enter the OTP received on your number
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      {mobile}
                    </Typography>
                    <Typography color="red">*</Typography>
                    <Button
                      disableRipple
                      onClick={() => setIsOtpScreen(false)}
                      variant="text"
                      sx={{
                        p: 0,
                        ":hover": { backgroundColor: "transparent" },
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                </Stack>
                <FormInput control={otpControl} name={"Otp"} />
              </Box>
            </Box>
            <Box sx={{ mt: "23px" }}>
              {verifyLoading ? (
                <Box
                  sx={{
                    border: "2px solid #1E4B8F",
                    width: { xs: "30%", sm: "25%", md: "22%" },
                    height: "35px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    alignContent: "center",
                  }}
                >
                  <Loader color={"#1E4B8F"} />
                </Box>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: { xs: "30%", sm: "25%", md: "22%" },
                    height: "35px",
                    borderRadius: "8px",
                    fontSize: "14px",
                  }}
                >
                  Submit
                </Button>
              )}
            </Box>
            {errorMessage && (
              <Toast
                variant="error"
                message={errorMessage}
                onClose={() => setErrorMessage("")}
              />
            )}
          </form>
        </StyledFormComponent>
      )}
    </StyledLoginBackground>
  );
};

export default Login;
