const Image = ({ ...props }) => {
  return (
    <img
      src={props.src}
      alt="logo"
      style={{ height: "100%", padding: "10px" }}
    />
  );
};

export default Image;
